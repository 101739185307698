<template>
    <header class="header">
        <!-- 点击显示弹窗 -->
        <div class="user-info" v-if="userinfo && user_token">
            <div class="agency-name">{{ userinfo.agency_short_name || userinfo.agency_long_name }}</div>
            <div class="agency-user-ident">{{ user_ident }}</div>
            <n-button class="sign_out" @click="signOut">退出登录</n-button>
        </div>
        <n-button class="not-login" type="primary" v-else @click="getLoginQrcode">登录</n-button>
    </header>
    <div class="wrapper">
        <div id="mse"></div>
        <div class="title-desc-wrapper">
            <div class="title">{{ liveObj?.title }}</div>
            <div class="desc">{{ liveObj?.desc }}</div>
        </div>
    </div>

    <!-- 登录弹窗 -->
    <n-modal v-model:show="loginModalVisible">
        <n-card
            style="width: 600px;"
            title="登录"
            :bordered="false"
            size="huge"
            role="dialog"
            aria-modal="true"
        >
            <div class="login-dialog">
                <div class="qrcode" v-if="loginState === 'empty'">
                    <img class="qrcode-img" :src="loginQrcodeSrc" alt />
                    <div class="text">微信扫一扫，进行登录</div>
                </div>
                <div v-if="loginState === 'has_scan'" class="has_scan">
                    <img class="result-img" src="../assets/success.svg" alt />
                    <div class="text">扫码成功，请在小程序上确认登录</div>
                </div>
                <div v-if="loginState === 'cancel_login'" class="cancel_login">
                    <img class="result-img" src="../assets/error.svg" alt />
                    <div class="text">
                        您取消了登录
                        <n-button strong secondary @click="getLoginQrcode">重新登录</n-button>
                    </div>
                </div>
                <div v-if="loginState === 'ok_login'" class="ok_login">
                    <div class="text">登录成功</div>
                    <div class="text">正在为您跳转</div>
                </div>
            </div>
        </n-card>
    </n-modal>
</template>

<script>
import { useMessage } from 'naive-ui'
import axios from "axios";
import FlvPlayer from 'xgplayer-flv';
import { useRoute } from "vue-router";
import { onMounted, ref, computed } from '@vue/runtime-core';
import { baseURL } from '../utils/utils';

export default {
    name: 'XgPlayerPage',
    setup () {
        const route = useRoute()
        const liveObj = ref()
        const message = useMessage()
        let player
        const getLiveStream = async () => {
            try {
                var { data } = await axios.get(`/live/playsrc/${route.query.roomid}`)
            } catch (error) {
                console.error('请求错误', error);
                message.error('路由参数错误')
            }
            if (data.error_code !== 0) {
                console.error('请求错误', data.msg);
                message.error(`请求错误: ${data.msg}`, { duration: 5000 })
                if (!userinfo.value || !user_token.value) {
                    getLoginQrcode()
                }
                return
            }
            // 获取url参数
            liveObj.value = {
                title: data.data.live_title,
                desc: data.data.live_desc,
                src: data.data.live_flv
            }
            console.log('liveObj---11', liveObj.value);
        }

        // 获取登录二维码
        const loginQrcodeSrc = ref()
        const uuid = ref()
        const getLoginQrcode = async () => {
            loginModalVisible.value = true
            loginState.value = 'empty'
            const { data } = await axios.get('/wx/web_login/login_qrcode')
            console.log('二维码数据', data);
            if (data.error_code !== 0) {
                message.error(data.msg)
                return
            }
            loginQrcodeSrc.value = `${baseURL}/images/${data.data?.qrcodeName}`
            uuid.value = data.data?.uuid
            getLoginInfo()
        }
        const loginModalVisible = ref(false)

        // 轮询登录数据
        const userinfo = ref()
        const user_token = ref()
        // 登录状态 即当前uuid的状态
        // empty 二维码还没扫描
        // has_scan 二维码已经被扫描，还未确认
        // cancel_login 用户取消登录
        // ok_login 用户确认登录/已经登录
        const loginState = ref('empty')
        const getLoginInfo = async () => {
            userinfo.value = sessionStorage.getItem('userinfo') ? JSON.parse(sessionStorage.getItem('userinfo')) : ''
            user_token.value = sessionStorage.getItem('user_token') ? sessionStorage.getItem('user_token') : ''
            if (userinfo.value && user_token.value) {
                loginState.value = 'ok_login'
                return
            }
            if (!loginModalVisible.value) {
                // 登录弹窗被关闭，关闭轮询
                return
            }


            const { data } = await axios.get(`/wx/web_login/${uuid.value}`)
            if (data.error_code === 1) {
                // 未扫描，继续轮询
                setTimeout(() => {
                    getLoginInfo()
                }, 800);
                return
            }
            if (data.error_code === 10) {
                // 扫描成功
                loginState.value = 'has_scan'
                setTimeout(() => {
                    getLoginInfo()
                }, 800);
                return
            }
            if (data.error_code === 11) {
                // 用户取消登录
                loginState.value = 'cancel_login'
                return
            }

            if (data.error_code === 0) {
                // 确认登录
                loginState.value = 'ok_login'
                userinfo.value = data.data
                user_token.value = data.data?.token
                // 设置缓存
                sessionStorage.setItem('userinfo', JSON.stringify(data.data))
                sessionStorage.setItem('user_token', data.data?.token)
                getLiveStream()
                location.reload()
                return
            }
        }

        onMounted(async () => {
            userinfo.value = sessionStorage.getItem('userinfo') ? JSON.parse(sessionStorage.getItem('userinfo')) : ''
            user_token.value = sessionStorage.getItem('user_token') ? sessionStorage.getItem('user_token') : ''
            await getLiveStream()
            // TODO 校验播放信息。
            // TODO 捕获播放错误
            player = new FlvPlayer({
                id: "mse",
                url: liveObj.value?.src,
                width: '100%',
                height: 'auto',
                playsinline: true,
                reload: true,
                isLive: true,
                autoplay: true,
                volume: 0,
                rotate: {
                    innerRotate: true
                },
                keyShortcut: "on",
                errorTips: `当前没有直播`,
                // 切换视频url会出现time,和progress。即使isLive:true
                ignores: ['time', 'progress']
            });

            player.on('error', (e) => {
                console.log('播放器发生错误', e);
                if (e.code === 404) {
                    // 当前没有在直播，3s轮询请求服务器，开始直播后自动刷新
                    setTimeout(() => {
                        player.src = liveObj.value?.src
                    }, 3000)
                }
            })
            console.log(player);
        })

        const user_ident = computed(() => {
            if (userinfo.value.user_ident === 1) {
                return '操作员'
            }
            if (userinfo.value.user_ident === 2) {
                return '机构管理员'
            }
            if (userinfo.value.user_ident === 9) {
                return '系统管理员'
            }
        })

        const signOut = () => {
            sessionStorage.clear()
            userinfo.value = ''
            user_token.value = ''
        }
        return { liveObj, loginQrcodeSrc, user_token, userinfo, uuid, loginState, getLoginQrcode, user_ident, signOut, loginModalVisible }
    }
}
</script>

<style lang="scss" scoped>
.header {
    display: flex;
    height: 60px;
    justify-content: right;
    padding-right: 40px;
    align-items: center;
    .user-info {
        display: flex;
        align-items: center;
        .agency-name {
            padding-right: 8px;
        }
        .agency-user-ident {
            padding-right: 24px;
        }
    }
}
.wrapper {
    margin-left: auto;
    margin-right: auto;
    @media screen and (min-width: 1152px) {
        width: 1152px !important;
    }
    #mse {
        max-height: 1000px;
        flex: auto;
    }
    .title-desc-wrapper {
        text-align: left;
        margin-top: 20px;
        .title {
            margin: 10px 0 10px 0;
            font-size: 30px;
            font-weight: 500;
        }
        .desc {
            color: #666;
        }
    }
}

.login-dialog {
    display: flex;
    justify-content: center;
    align-items: center;
    .qrcode,
    .has_scan,
    .cancel_login {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        img {
            width: 280px;
            height: 280px;
        }
        .result-img {
            width: 210px;
            height: 210px;
        }
        .text {
            text-align: center;
            font-size: 22px;
            margin-top: 20px;
            margin-bottom: 30px;
            display: flex;
            flex-direction: column;
            .n-button {
                margin-top: 8px;
            }
        }
    }
}
</style>